var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'customers-types-create' })}}},[_c('i',{staticClass:"bi bi-plus-square fs-5"}),_vm._v(" Créer un nouveau type de client ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.customer_types),function(customer_type,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_c('button',{staticClass:"btn btn-link ms-2",on:{"click":function($event){return _vm.$router.push({
                name: 'profile-customer_type',
                params: {
                  reference: customer_type.reference,
                },
              })}}},[_vm._v(" "+_vm._s(customer_type.reference)+" ")])]),_c('td',[_vm._v(" "+_vm._s(customer_type.fullName)+" ")])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")])])])
}]

export { render, staticRenderFns }