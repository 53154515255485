<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'customers-types-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square fs-5"></i> Créer un nouveau type de client
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Désignation</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(customer_type, index) in customer_types" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            <button
              class="btn btn-link ms-2"
              @click="
                $router.push({
                  name: 'profile-customer_type',
                  params: {
                    reference: customer_type.reference,
                  },
                })
              "
            >
              {{ customer_type.reference }}
            </button>
          </td>
          <td>
            {{ customer_type.fullName }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
    }),
  },
  mounted() {
    this.$store.dispatch("customer/getCustomerTypes");
  },
  methods: {
    
    async refresh() {
      return await this.$store.dispatch("customer/getCustomerTypes");
    },

  },
};
</script>
